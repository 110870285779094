class Config {
    constructor() {
        this._nodeEnv = process.env.NODE_ENV;
    }

    /**
     * @param {boolean} value
     */
    set IsStandAlone(value) {
        this._isStandAlone = value;
    }

    get IsStandAlone() {
        return this._isStandAlone;
    }

    get NodeEnvironment() {
        return this._nodeEnv || process.env.NODE_ENV;
    }

    get MfeRootRoute() {
        return this._isStandAlone ? '/' : process.env.MFE_ROOT_ROUTE
    }

    get RootElementSelector() {
        return process.env.DEV_ROOT_ELEMENT_SELECTOR || 'token_management_ui'
    }

    get RootElementContainer() {
        return process.env.ROOT_ELEMENT_CONTAINER || 'shipbob_token_management_ui'
    }

    get AuthenticationAuthority() {
        return process.env.AUTHENTICATION_AUTHORITY;
    }

    get ClientId() {
        return process.env.CLIENT_ID;
    }

    get ClientScope() {
        return process.env.CLIENT_SCOPE;
    }

    get Locale() {
        return process.env.LOCALE || 'en-US';
    }

    get FallbackLocale() {
        return process.env.FALLBACK_LOCALE || 'en-US';
    }

    get ExternalAuthBaseUrl() {
        return process.env.EXTERNAL_AUTH_BASEURL;
    }

    get PatCreationEndpoint() {
        return process.env.PAT_CREATION_ENDPOINT;
    }

    get GetPATDetailsEndpoint() {
        return process.env.GET_PAT_DETAILS_ENDPOINT;
    }

    get ConfigEndpoint() {
        return process.env.CONFIG_ENDPOINT || process.env.PAT_CONFIG_ENDPOINT;
    }

    get PatRevokeEndpoint() {
        return process.env.PAT_REVOKE_ENDPOINT;
    }

    get AppInsightsConnectionString() {
        return process.env.APPINSIGHTS_CONNECTIONSTRING;
    }

    get IsTelemetryEnabled() {
        return !!this.AppInsightsConnectionString;
    }

    get AppCreationEndpoint() {
        return process.env.APP_CREATION_ENDPOINT;
    }

    get GetAppDetailsEndpoint() {
        return process.env.GET_APP_DETAILS_ENDPOINT;
    }

    get MarketPlaceAPIBaseURL() {
        return process.env.MARKETPLACE_API_BASEURL;
    }

    get PublishAppEndpoint() {
        return process.env.PUBLISH_APP_ENDPOINT;
    }

    get UploadAppIconEndpoint() {
        return process.env.UPLOAD_APP_ICON_ENDPOINT;
    }
    
    // Only for tests
    /**
     * @param {string} value
     */
     set NodeEnvironment(value) {
        this._nodeEnv = value;
    }
}

const configService = new Config();

export default configService;